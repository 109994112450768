import React from 'react'
import { Link } from 'react-router-dom'
import "../../css/Footer.css"
import { FaArrowUp } from "react-icons/fa";

const Footer = () => {
  return (
   <>
   <div className="footer-area">
   <div className="container">
   <div className="row">
   <div className="col-lg-4 col-md-6 col-sm-6">
   <div className="single-footer-widget">
   
         
       
       <h3>Contact Us</h3>
       <ul className="footer-contact-info">
           <li>TRADE WISE SPECIALIZED SOLUTION FOR
           Inventory & Accounting Software
           </li>
        
           <li>Email:<Link to='' style={{textDecoration:"none"}}>hr@anssoftware.com</Link> </li>
           <li>Call: <Link to="tel:+91-9938369600" style={{textDecoration:"none"}}>+91-9777631607, +91-9938369600</Link></li>
       </ul>
       <ul className="social">
              <li><Link to="#" target="_blank"><i class='bx bxl-facebook'></i></Link></li>
           <li><Link  to="#" target="_blank"><i class='bx bxl-twitter' ></i></Link></li>
           <li><Link  to="#" target="_blank"><i class='bx bxl-linkedin' ></i></Link></li>
       </ul>
   </div>
</div>



<div className="col-lg-4 col-md-6 col-sm-6">
<div className="single-footer-widget">
    <h3>Our Services</h3>
    <ul className="services-list">
        <li><Link to="/webdesign">Website Designing</Link></li>
        <li><Link to="/softwareDevelopement">Software Development</Link></li>
        <li><Link to="/ecommerceDesignAndDevelopement">E-Commerce</Link></li>
        <li><Link to="/mobileappdev">Mobile App Development</Link></li>
        <li><Link to="/technologyServices">Technology Services</Link></li>
        <li><Link to="/Webhosting">Domain & Web Hosting</Link></li>
        <li><Link to="/cloud">Cloud</Link></li>
        <li><Link to="/ithardware">IT Hardware Product</Link></li>
       
    </ul>
</div>
</div>

<div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Others</h3>

                            <ul className="support-list">
                                
                                <li><Link to="/career">Career</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="#">Terms & Conditions</Link></li>
                               
                               
                            </ul>
                        </div>
                    </div>
                    
 </div>
   </div>

   </div>

   <div class="go-top" style={{}}>
  <i class='bx bx-chevron-up'></i>

</div>

  
  
   
   
   
   
   
   </>
  )
}

export default Footer

import React from 'react'
import { FiCheckSquare } from "react-icons/fi";
import manageimg from "../../images/manage.jpg"
import aws22 from "../../images/aws22.jpg"
import azure from "../../images/azure.png"

const Cloud = () => {
  return (
   <>
   <div className="bg-light container-fluid">
   
   <section className="container">

       <div className="row py-3">

           <div  className="row text-center">
               <h4>BEST VPS HOSTING SERVICES</h4>

           </div>

       </div>


       <div>
           <div>
               <p>
                   VPS (Virtual Private Server) hosting is the ultimate choice for high performance, reliability and faster website. Shared hosting imposes certain limitations, and when your website requires constancy in speed, more space, RAM and other resources for the sudden spurt in your website traffic you require VPS hosting where you can take full control in your hands as the server is dedicated to your websites.<br /><br />

                   ANS Software offers Low-cost VPS solutions without compromising on quality or performance. As a client-first company, we strive to keep the costing affordable for all and help them achieve state of the art services and eventually level up their digital presence. Our cheap VPS hosting in India is a superior hosting solution in terms of resources, features, and other facilities, and is highly affordable as it costs less than dedicated server hosting. NextraOne’s VPS hosting India plans are fully managed without any extra charges and are backed up with 24×7 true technical support.<br /><br />

                   Virtual private server hosting provides the performance, control, flexibility and security that is almost equivalent to that of a dedicated server, and affordability of shared hosting server. We love to help the growing businesses get excellent digital infrastructure without inflating their budget.



               </p>
           </div>
       </div>



   </section>

   </div>
   <section className="container">
            <div className=" row mb-3 mt-5">
                <h5>VPS Hosting Features</h5>
            </div> 
            <dl>
                <dt className="col-sm-3">Concrete Security: </dt>
                <dd className="col-sm-9"><p>No need to worry about any unauthorized access. Since there is a separate OS; therefore no other websites can interfere with your confidential files.</p></dd>

                <dt className="col-sm-3">High Speed:</dt>
                <dd className="col-sm-9">
                    <p>We ensure you with a high loading speed of your web pages with the server getting recognized instantly within few seconds.</p>

                </dd>

                <dt className="col-sm-3">Solitary Control/ Root Access: </dt>
                <dd className="col-sm-9"><p>We provide full administrative access i.e. Root access with every server to facilitate full customization of the server environment based on your requirements.</p></dd>

                <dt className="col-sm-3 text-truncate">Scalable:</dt>
                <dd className="col-sm-9"><p>Start small and move to a more powerful VPS as your website grows. You can upgrade the existing one with ease. Just tell us about the requirement and your plan will be upgraded with our cheap VPS hosting prices.</p></dd>

                <dt className="col-sm-3">99.99% server Uptime: </dt>
                <dd><p> Your server is backed by our 99.9% uptime guarantee. All our VPS are set up in a fully redundant infrastructure with 100% power availability.</p></dd>

                <dt className="col-sm-4">Real-time support round the clock: </dt>
                <dd className="col-sm-8"><p>Our support team works around the clock and is available via Live Chat and our Helpdesk to assist you with your hosting service, whenever you need it.</p></dd>


            </dl>

        </section>
        <div className="row">
        <h3 className="text-center mt-3 mb-4">Experience the Next Generation Cloud Hosting  </h3>
    </div>
    <br />
    <br />

        <div className="bg-light container-fluid">
      
            

            <section className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-6 col-lg-6 col=12 ">
                        <h5>Focus on Your Applications in AWS Not Your AWS Infrastructure </h5>

                        <p >AWS is as complex as it is powerful. Choosing the right instances and tools for your applications, designing redundant and secure environments, managing the migration, optimizing deployments - it requires expertise and a whole lot of time.</p>
                        <img src={manageimg} height="230" width="370"/>

                       
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col=12 ">
                        <h5 >Managed Google Cloud Platform</h5>


                        <p >Accelerate innovation and cost savings by shifting the intensive, day-to-day operations of Google Cloud Platform.We will work with you to identify the scope and criticality of your applications and determine the service level that best addresses your needs.</p>


                        <h5>Exciting features of Google Cloud management</h5>


                        <p>
                            <ul>
                                <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Google Cloud Accounts Management And Auditing.</li>
                                <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Google Cloud Access Control For Complete Security.</li>
                                <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Google Cloud Analytics And Activity Logging.</li>
                                <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Google Cloud Management.</li>
                                <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Google Cloud Migration Services.</li>
                                <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Database Management Services.</li>
                            </ul>
    </p>
 </div>
</div>
</section>
 </div>  

 <section className="container mt-5 mb-5">
            <div className="row">

                <div className="col-sm-12 col-md-6 col-lg-6 col=12  ">
                    <h5 >Focus on Your Applications in AWS Not Your AWS Infrastructure </h5>

                    <p>AWS is as complex as it is powerful. Choosing the right instances and tools for your applications, designing redundant and secure environments, managing the migration, optimizing deployments - it requires expertise and a whole lot of time.

</p>
                    <img src={aws22}  height="230" width="370" />
                   
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col=12 ">
                    <h5 >Amazon AWS Management</h5>


                    <p >Amazon Web Services (AWS) - the secure cloud services platform helps businesses scale and grow by building sophisticated applications with increased flexibility, scalability and reliability. AWS offers businesses the capabilities to scale and grow faster with on-demand instances, improved application development, and storage options to meet growing needs. Start configuring your custom AWS solution from the wide range of plans on offer.</p>


                    <h5 >Exciting features of AWS management </h5>


                    <p >
                        <ul>
                           
                            <li   data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare  style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/> AWS Accounts Management And Auditing.</li>
                            <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare  style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Cloud Access Control For Complete Security.</li>
                            <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare  style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Cloud Analytics And Activity Logging.</li>
                            <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>AWS Cloud Management.</li>
                            <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>AWS Migration Services</li>
                            <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Database Management Services.</li>
             </ul>
                     </p>
</div>
</div>
</section>
<section className="container">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col=12 ">
                    <h5 >Microsoft Azure Management</h5>


                    <p >Managed Microsoft Azure by SingleHop not only keeps your team focused on major business objectives, it lets you retain control - just as if it were managed in house. Leveraging our powerful cloud automation platform and our dedicated team of Microsoft Certified Engineers, we deliver superior performance for your Azure workloads. Maximize all that Azure and Microsoft Cloud has to offer with a solution built and priced specifically for your business size.</p>


                    <h5 >Exciting features of Microsoft Azure management</h5>


                    <p >
                        <ul>
                        <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Azure Accounts Management And Auditing.</li>
                           <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Cloud Access Control For Complete Security.</li>
                           <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Cloud Analytics And Activity Logging.</li>
                           <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Azure Cloud Management.</li>
                           <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Azure Migration Services.</li>
                           <li  data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"><FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px',marginTop:'5px'}}/>Database Management Services.</li>
                           
                        </ul>

                              </p>
</div>







                <div className="col-sm-12 col-md-6 col-lg-6 col=12 ">
                    <h5 >Experience Azure - the globally trusted Cloud platform</h5>

                    <p >Get the freedom to build apps and deploy them the way you wantWith a variety of tools, applications and development frameworks available across globally spread datacenter networks, build any application suiting your business needs and experience powerful scalability and flexibility of cloud.</p>
                    <img src={azure} height="230" width="370"/>

                    
                </div>
    



            </div>




        </section>
   
   
   
   
   
   
   
   </>
  )
}

export default Cloud

import React from 'react'
import { Link } from 'react-router-dom'
import banner2 from '../../images/banner2.jpg'
import '../../css/About.css'
import {aboutdata} from '../pages/data/Data'
import Featurecard from './Featurecard'

const About = () => {
  return (
    <>
    <div class="breadcumb-area black-opacity bg-img-3">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="breadcumb-wrap">
                    <h2>About ANS Software</h2>
                </div>
            </div>
        </div>
    </div>
     
</div>
 
    <div className="container">
    <div className="row align-items-center">

    <div className="col-lg-6 col-md-12 mt-4">
    <div className="section-title mb-3">
        <p style={{color:'teal',fontSize:'25px',fontWeight:'600'}}>A Leading IT Consulting And <span>Software Services Company In Odisha</span></p>
    </div>
    <p className="text-align-justify"> We are young and energetic technical savvies and in hungry of latest innovation technologies on web. We will apply all the technology evolution strategies in our projects. Our smart work will often describe who we are! We are providing cost effective SEO services & quality website development in different CMS technologies around the globe.We work for the client's satisfaction and will stand with you to grow your business organically. Running a business not only requires hard work but also requires effective smart work. We shall do both the works for an unbelievable cost!</p>
    <h3 className='mb-3'style={{fontSize:'20px'}}>A Leading IT Industry & Developement Company </h3>
    <p className="text-align-justify">Some of the finest websites were designed, developed and marketed by us. We originated from futuristic technology and progressing towards success with a great desire. We work with self-derived strategies, as we have experienced everything on our own. We are equipped with the state-of-the-art work station in the website development and testing. So, results delivered on time, every time! Your success is our bread and butter!</p>
    <h3 className='mb-3'style={{fontSize:'20px'}}>Happy to say</h3>
    <p className="text-align-justify">We always happy to say loudly, we smell & feel the success every day because of the our experts in execution planning with website design and digital marketing. They are always adopting with our valuable clients to satisfy on their requirement in each perspective, so our valuable clients make us more valuable in our success. While you work with us you feel, we are in right place & right time.</p>
</div>

<div className="col-lg-6 col-md-12" style={{marginTop:'100px'}}>

{

    aboutdata.map((Url,id)=>{
    
   
    return(
    <>
    
    
<div className='fun-facts section'>
<div className="single-fact "  data-aos="fade-left" data-aos-delay="200">
<div className="icon "  ><i class='bx bx-right-arrow-alt'></i></div>
									<div className="">
										<Link to={Url.url}><h5 className="mt-4 " ><span >{Url.title}</span></h5></Link>
									</div>



                                    </div>
</div>

    
    
    </>
    )
    })
    
    
    }
</div>


   </div>
   </div>
<br/><br/>
<Featurecard/>
    </>
  )
}

export default About

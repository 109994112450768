import React from 'react'
import "../../css/Career.css"
import career from "../../images/career.png"

const Career = () => {
  return (
   <>
   <div className="page-career">
   <img src={career} alt="career"  className="img-fluid"/>
   <div className="section-title">
       <h1 className="text-center mt-5">Current Openings <span style={{color:'crimson'}}>ANS Software</span></h1>
       <p></p>
   </div>
</div>
   <div className="container">
   <div className="row align-items-center">
   
   <div className="col-lg-12 col-md-12">
   <div className="about-content">
       <h5 style={{color: "#009999"}}>What will you need to join our team?</h5>
       <p>At ANS Software, we encourage professionalism mixed with fun and creativity to enhance and elevate an individual's career growth.</p>
       <p>Energetic. Passionate. Team Player. If you qualify for all three categories, we welcome you to review our list of open positions. However, if you don't see an opening that fits, click here to submit your resume and tell us why we should hire you anyway!</p>
       
       <h5 style={{color: "#009999"}}>On the Job Training</h5>
       <p>We provide on the job training to our employees to enable them to carry out their tasks in the best possible manner and within the shortest possible time. We have experts in the business to train the new recruits. The training generally lasts for a period of one year. It is followed by a brief test to gauge the skill levels that the employees have acquired.</p>
       <h5 style={{color: "#009999"}} >Appraisals</h5>
       <p>Appraisals are a regular feature of our company and occur at an interval of six months. Those who have been freshly recruited are evaluated by us for a six month time frame before being made a permanent part of the company. For permanent employees, an evaluation is undertaken by work place superiors every six months.</p>
       <h5 style={{color: "#009999"}} >Fresher</h5>
       <p>Being into collage is a fun but being at ANS Software is more fun, challenging and exciting. Here you can join a group of young professionals as bright and dynamic as you are. We all provide a great canvas to fresh talent where they can fill the colour of there imagination.

       </p>
       
       
       </div>
</div>
<h1  style={{color: "#009999"}}  className="mt-5 mb-2">Openings @ ANS Software</h1>
                    <p>you have development experience then apply for a full-time position with us. We prefer online portfolios and emails. You can also drop your resume at our mailing ID</p>
             
<table style={{width: "100%"}}className="table">
<thead>
    <tr className="warning">
        <th>Serial no.</th>
        <th>Job Title</th>
        <th>Qualification</th>
        <th>No. of Post</th>
        <th>Apply Here</th>
    </tr>
</thead>
<tbody>
    <tr className="success">
        <td>1</td>
        <td>Software Developers</td>
        <td>B.TECH/BSC-IT/ Any gratuate</td>
        <td>3</td>
        <td>
            <input type="submit" name="ctl00$ContentPlaceHolder1$Button1" value="Apply Now" id="ContentPlaceHolder1_Button1" /></td>
    </tr>
    <tr className="danger">
        <td>2</td>
        <td>Mobile App Developers(Android)</td>
        <td>B.TECH/BSC-IT/ Any gratuate</td>
        <td>2</td>
        <td>
            <input type="submit" name="ctl00$ContentPlaceHolder1$Button2" value="Apply Now" id="ContentPlaceHolder1_Button2" /></td>
    </tr>
    <tr className="info">
        <td>3</td>
        <td>Marketing Executive</td>
        <td>BCA/BSC-IT/ Any gratuate</td>
        <td>6</td>
        <td>
            <input type="submit" name="" value="Apply Now" id="ContentPlaceHolder1_apply" /></td>
    </tr>
    
</tbody>
</table>



  








   
  </div>
  </div>
   
   
   </>
  )
}

export default Career


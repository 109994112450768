import React from 'react'
import "../../css/Softwaredevelopement.css"
import productdev from '../../images/productdev.jpg'
import webdev from '../../images/webdev.jpg'
import ui from '../../images/ui.jpg'
import erp from '../../images/erp.jpg'
import ecom from '../../images/ecom.jpg'
import frontent from '../../images/frontend.jpg'

const SoftwareDevelopement = () => {
  return (
   <>
   <div className='banner'>
   
   </div>
   <section className="py-5">
    <div className="container">
    <h1 className="text-hr pms_margin">
              <span className="text-hr__text">Software Development Services</span>
        </h1>
       
             
              <p className="pms_about pl-lg-0 truncate_about_us" style={{marginTop:'25px'}}>
                       ANS Software group of enthusiastic engineers driving IT Company in Odisha.
                  Since 2020, we've aggregated so many projects development experience in billing software, record keeping software, stock management ,Hr management,Travels and Tourisim management,School Management Software, ANS Software has earned a reputation as a reliable development partner. 
                  Your search for a reliable Software Development Company in Odisha ends here! ANS Software is widely acclaimed for its world-className software solutions that drive innovation in the current digital era. We deliver unequaled solution execution through improved software capacities without the need to straightforwardly enlist software developers to meet cross-industry needs. We invest heavily in our capacity to give innovation that can work and develop with organizations around the globe. Experienced Software Development Company in Odisha give dependable and adaptable procedures that are fit too far off projects. Their timely delivery of solutions can emulate your own dedicated in-house team of developers. We are knowledgeable with the most recent innovation on the lookout and can give you endeavor arrangements that coordinate every one of your necessities.  
                        </p> 



                        

</div>
</section>
<div  className='container' style={{marginTop:"-50px",marginTop:'5px'}}>

<div className='title'>
<h5 className=" py-2">
Product Development
</h5>
    </div>
<div className="row">


<div className="col-lg-5 ">
    <img src={productdev} />
</div>
<div className="col-lg-1"> </div>

<div className="col-lg-6">
    
    

        <div>
            <p>

             Get quality product development solutions from our devoted to reevaluate group of software developers who build customized solutions that oblige every one of your exceptional business needs. We offer versatile product development services in web, mobility applications, custom software designs, wearable device solutions, stand applications, AI and Blockchain development services, data analytics and much more.

            </p>
        </div>
    
</div>



</div>

</div>
<div className='container'style={{marginTop:"-50px",marginTop:'5px'}}>
<div className='title'>
<h5 className=" py-2">
Web Development
</h5>
    </div>
    <div className="row">
    <div className="col-lg-5 ">
        
 

        <div>
        <p>

        We conceptualize, design, develop and build responsive websites and web applications that expand client engagement by delivering an extraordinary user experience. We are a top-rated Web hosting Company in Odisha.

    </p>
        </div>
    
</div>
<div className="col-lg-1 "></div>


    <div className="col-lg-6 ">
    <img src={webdev} />
    </div>
    
 
    
    
    
    </div>


</div>
 


<div className='container 'style={{marginTop:"-50px",marginTop:'5px'}}>



<div className='title'>
<h5 >
UX/UI design
</h5>
   </div>             
               
   <div className="row" >
                <div className="col-lg-5 ">
                <img src={ui}/>
                       </div>

                       <div className="col-lg-1"> </div>

                       <div className="col-lg-6">
                    
                     
                       <section>
   
                           <div>
                               <p>
   
                                   With our creative design thinking expertise and customer engagement rich experience, we specialize in delivering creative user interfaces by bridging user-focused appearance with business functionality. We always aim to build a clean, intuitive, adaptive, and user-friendly UX/UI design that aligns with the latest business trends.
   
                               </p>
                           </div>
                       </section>
                   </div>


            </div>
</div>


<div className='container' style={{marginTop:"-50px",marginTop:'5px'}}>


            
            
            <div className='title'>
                    <h5 className=" py-2">
                    ERP Software Development
                </h5>
                </div>
                <div className="row">
                <div className="col-lg-6 ">

                    <div >
                        <p>

                           Leverage our expertise in enterprise resources planning software development to actualize seamless integration at each stage of your business. We know that every company follows unique business processes, requirements, and challenges. We guarantee that our ERP software system is in accordance with your business targets notwithstanding the size and nature of your organization.

                      

                          Influence our aptitude in big business assets by arranging programming advancement to realize consistent incorporation at each phase of your business. We realize that each organization follows extraordinary business cycles, prerequisites, and difficulties. 

                        </p>
                    </div>
              
            </div>
            <div className="col-lg-1"> </div>

            <div className="col-lg-5">
                <img src={erp} />
            </div>
        </div>

        </div>
        <div className='container' style={{marginTop:"-50px",marginTop:'5px'}}>

        
        <div className='title'>
        <h5 className=" py-2">
        E-Commerce Development
     </h5>
     </div>
     <div className="row">

        <div className="col-lg-6">
            <img src={ecom}/>
        </div>

        <div className="col-lg-6 ">
              
            <section>

                <div>
                    <p>

                       We give multi-platform upheld e-commerce enterprise business solutions that are most appropriate to a full-scale market place. From e-commerce design wireframing, product inventory configuration and installation of the shopping cart to secure payment gateway integration, we provide full-stack e-commerce development solutions.
                    </p>
                </div>
            </section>
        </div>


    </div>

    </div>
    <div className='container'style={{marginTop:"-50px",marginTop:'5px'}}>
    
   
    

    
    <div className='title'>
          <h5 class="py-2">
            Front-End Development
        </h5>
        </div>
        <div class="row">
        <div class="col-lg-6">
            <div >
                <p>

                    Advantage from our full-stack front-end development expertise to create interactive UI, client-driven, web mobility enterprise business solutions with ensured content clarity and usability. We likewise ensure that our front-end development services incorporate all the requirements of our customers by providing accessibility across multiple web platforms.

                </p>
            </div>
       
    </div>
    <div className='col-lg-1'></div>

    <div class="col-lg-5" >
    <img src={frontent} alt=""/>
</div>

</div>
    
    
    
    </div>
   
   
   
   
   
   
   
   
   </>
  )
}

export default SoftwareDevelopement

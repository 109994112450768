import React from 'react'
import "../../css/Ithardware.css"
import ithardware from '../../images/ithardware.png'
import desktop from '../../images/desktop.png'
import laptop from '../../images/laptop2.png'
import epbax from '../../images/epbax.png'

import ups from '../../images/ups.png'
import printer from '../../images/printer.png'
import security from '../../images/security.png'
import { FiCheckSquare } from "react-icons/fi";
import { Link } from 'react-router-dom'

const Ithardware = () => {
  return (
   <>


   <section id="itpage" className="d-flex align-items-center">
   
   <img src={ ithardware} width="100%"  alt=''/>
   
   
   </section>
   <div className="form-v1-content">
             <div className="row main_head_form">
                 <div className="col-12">
                         <h3 className=" text-center text-white" style={{wordSpacing:'2px'}}>ANS Software  provides total IT equipment solution throughout the technology life cycle continuum of your hardware. </h3>
                
                      
                 </div>
             </div>
             </div>



             <section className="new-section-padding pb-0" style={{marginBottom:'80px',marginTop:'20px'}}>
             <div className="container" style={{marginBottom:'80px'}}> 
         <h3 className="text-hr pms_margin main_heading_comman" >
             <span className="text-hr__text">IT Product (Desktop, Laptop, Printer and Other Peripherals)
    Detail
    </span>
    
         </h3>
         
    
                 <div className="row mt-5 mb-5">
                     <div className="col-md-5">
                         
                         
    
                    <ul className="for_ul">
                        <li className="for_list ">
                           
                            <p className="pms_about6 p-0" >
                            <FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px'}} />
                              Desktop & Laptop(HP, Dell, Lenovo)
                            </p>
                         </li>
                        <li className="for_list ">
    
                             <p className="pms_about6 p-0">
                             <FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px'}}/>
                             Printer (HP Canon)
                            </p>
    
                              </li>
                        <li className="for_list">
                             <p className="pms_about6 p-0">
                             <FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px'}}/>
                             UPS (Numeric, Cyber Power, Microtech)
                            </p>
    
                              </li>
                        <li className="for_list" >
                             <p className="pms_about6 p-0">
                             <FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px'}}/>
                            Photocopier (Xerox Canon)
                            </p>
                           
                        </li>
    
    
                         <li className="for_list ">
                             <p className="pms_about6 p-0">
                             <FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px'}}/>
                            Security (CCTV Surveillance, Biomatrix, Firewall)
                            </p>
                           
                        </li>
                        <li className="for_list "  >
                             <p className="pms_about6 p-0">
                             <FiCheckSquare style={{ color:'crimson',fontWeight:'100',marginRight:'4px'}}/>
                            EPBAX
                            </p>
                           
                        </li>
                    </ul>
                         <p>
                             We have gained a remarkable position in the market that is involved in offering Desktop, Laptop, Printer, UPS and Other Peripherals.
                         </p>
                     </div>
                     <div className="col-md-7 squarertegw">
                       
    
                          <div className="row text-center">
                            <div className="col-md-4 col-xs-6">
                                <div className="car_d">
                                     <Link className="img-car_d" href="#">
                                        <img src={desktop} alt="Best IT Products Printer, UPS Store in Odisha" />
                                    </Link>
                                   
                                  
                                </div>
                            </div>
    
                               <div className="col-md-4 col-xs-6">
                                <div className="car_d">
                                     <Link className="img-car_d" href="#">
                                        <img src={laptop}alt="web design and development services in Odisha"  />
                                    </Link>
                                   
                                  
                                </div>
                            </div>
                    <div className="col-md-4 col-xs-6">
                                <div className="car_d">
                                     <Link className="img-car_d" href="#">
                                        <img src={epbax}alt="IT Products Desktop, UPS, Photocopier Shop in Odisha" />
                                    </Link>
                                   
                                  
                                </div>
                            </div>
    
    
                              <div className="col-md-4 col-xs-6">
                                <div className="car_d">
                                     <Link className="img-car_d" href="#" alt=''>
                                        <img src={ups} alt="web design and development services in Odisha" />
                                    </Link>
                                   
                                  
                                </div>
                            </div>
    
                   <div className="col-md-4 col-xs-6">
                                <div className="car_d">
                                     <Link className="img-car_d" href="#">
                                        <img src={printer} alt="IT Products Desktop, UPS, Photocopier Shop in Odisha" />
                                    </Link>
                                   
                                  
                                </div>
                            </div>
    
    
                        </div>
    
                   
                     </div>
                 </div>
                  <h3 className="text-hr pms_margin main_heading_comman" >
             <span className="text-hr__text">Your Favourite Store for All Computer Needs!</span>
    
         </h3>
            <div className="row mt-5">
               <div className="col-md-6">
                   <div className="sdkfsk d-flex justify-content-center align-items-center">
                               <img src={security} className="img-res"
                       alt="Get best computer antivirus software for total security" />
                          </div>
                  
                   
                </div>
                <div className="col-md-6 p-4">
                     <p className="truncate_about_us pms_about p-0" >
                             Get IT products trade finance or working capital from leading brands for your export / import at best terms with ANS Software.You can also get suppliers across India for IT products with their company profile, office addresses, contact details, phone numbers and email addresses. You can also view suppliers detailed profile with trade history if exporter, financial and registration details, criminal charges if any, certifications & awards. ANS Software helps you to find suppliers that deals in {0} products and all its sub-products from near your location, in your city and in your state. Get in touch with ANS Suftware to find more information. 
                    </p>
    
                </div>
    
                 
            </div>
    
     </div>
       
    
        </section>
   
   
   
   
   
   </>
  )
}

export default Ithardware

import React from 'react'
import "../../css/Webdesignservice.css"
import webdesign from'../../images/webdesign.png'
import  {webdesigndata} from '../pages/data/Data'

const WebdesignServices = () => {
  return (
   <>
   <section id="heros1" className="d-flex align-items-center">
   
   <img src={webdesign} className="img-fluid" alt='Best Software company in Odisha' />
   
   
   </section>

<div className="page-title-area1">
    
    <div className="section-title ">
        <h1 className='text-center'style={{fontSize:"40px"}}>Website<span style={{color:'var( --bs-danger)'}}> Design Company</span></h1>
    </div>
</div>
<section className=' title-bar'>

<div className="container pos-rel">
 <div className="row align-items-center ">
 
 
 <div className="col-md-12 col-lg-8 ">
 <div className="text-left"> 
   <h3 className="h4-md mb-3 txt-blue fw-7" >Best Website Design Company in Odisha</h3>
   <p>Website Design is the cycle development steps used to mix client needs with business objectives to assist brands with making reliably. A product like custom applications such as billing software, record keeping software, stock management,Hr management,Travels and Tourisim management,School Management Software.</p>
   <p>ANS Software has to have the experience and expertise in website design, which generates sales and is an asset to your business. Our website product designing services covers strategic planning, business intelligence, creative, application development, product and service promotion and maintenance solution.</p>
   <ul className="list-unstyled icons-listing   mb-2">
     <li data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s" ><i className="bx bx-check" ></i>The company started developing customised web/mobile applications since 2020. </li>
     <li data-aos='fade-up' data-aos-duration="0" data-aos-delay="0.1s"><i className="bx bx-check" ></i>People believe in ANS Software to develop a software product design for them.</li>
     <li data-aos='fade-up' data-aos-duration="0" data-aos-delay="0.2s"><i className="bx bx-check" ></i>Available at most reasonable prices with affordable quality services.</li>
     <li data-aos='fade-up' data-aos-duration="0" data-aos-delay="0.3s"><i className="bx bx-check" ></i>We Having Expertise in Software and Web Application Product Design.</li>
   </ul>
   <p> We are one of the few companies that develop a perfect software product for their customer, people come to us and tell their stories about how they found that most new companies cannot develop software products. We assure them that we cannot break your trust, so we work honestly. </p>
 </div>
</div>
<div className="sidebar col-lg-4 col-md-12" >
        
<div className="widget-wrap" >
<h5 className="h3-md fw-7  text-blue text-center">Benefit of Web Designing</h5>
<ul className="list-unstyled icons-listing theme-orange mb-0">
 <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"></i>More sales and customers with more quality on your website</li>
 <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"></i>Usability for an intuitive and easy to navigate web design</li>
 <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"></i>Quality content to retain your user</li>
 <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s"></i>Optimize your website for better positioning</li>
</ul>
</div>
</div>
 </div>
 </div>
</section>
<section class="container text-center" style={{marginBottom:'10px'}}>
<div class="row">
   <h1>Our Services On Web Design</h1>
   <hr className="w-auto w-25" />
</div>

</section>

<div className='container' >


<div className="row d-flex ">

{

webdesigndata.map((designdata,id)=>{


return(
<>
<div class="col-lg-4 col-sm-6 mt-2 " key={id} data-aos={designdata.dataaos}>
<div class="single-designdata text-center">
    <img src={designdata.png} height={150}  style={{marginBottom:'20px'}} width={200} />
    <h5>
      {designdata.title}
    </h5>

    <p >{designdata.description}</p>
</div>
</div>

</>
)
})


}

</div>
</div>





   
   
   
   
   
   </>
  )
}

export default WebdesignServices

// import logo from './logo.svg';
import './App.css';


import "jquery-ui-dist/jquery-ui";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';

import { Route, Routes } from 'react-router-dom';

import About from './components/pages/About';
import Contact from './components/pages/Contact';
import SoftwareDevelopement from './components/pages/SoftwareDevelopement';
import WebdesignServices from './components/pages/WebdesignServices';
import Ithardware from './components/pages/Ithardware';


import EcommerceDesignAndDevelopement from './components/pages/EcommerceDesignAndDevelopement';
import Career from './components/pages/Career';

import  Cloud  from './components/pages/Cloud';


import Home from './components/pages/Home';

import { useEffect } from 'react';
import Mobileappdev from './components/pages/Mobileappdev';
import Page404 from './components/pages/Page404';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './components/pages/Footer';
import Webhosting1 from './components/pages/Webhosting1';

import Schoolmanagementsystem from './components/pages/Schoolmanagementsystem';
import { Travelanstourism } from './components/pages/Travelanstourism';
import Product from './components/pages/Product';
import Navbar1 from './navbar/Navbar1';







AOS.init();


function App() {
  useEffect(() => {
 
    $(window).on('load', function() {
      $('#preloader').fadeOut();
    });
  
  
    $(function(){
     
      $(window).on('scroll', function(){
          var scrolled = $(window).scrollTop();
          if (scrolled > 600) $('.top-arrow').addClass('active');
          if (scrolled < 600) $('.top-arrow').removeClass('active');
      });  
     
      $(function(){
      
        $(window).on('scroll', function(){
            var scrolled = $(window).scrollTop();
            if (scrolled > 600) $('.go-top').addClass('active');
            if (scrolled < 600) $('.go-top').removeClass('active');
        });  
      
        $('.go-top').on('click', function() {
            $("html, body").animate({ scrollTop: "0" },  500);
        });
    });
  });
  }
  )
  
  

  return (
  <>
  <Navbar1/>

  <Routes>
  <Route path="/" exact element={<Home />} />
  <Route path="about" element={<About />} />
  <Route path="contact" element={<Contact/>} />
  <Route path="webdesign" element={<WebdesignServices />} />
  <Route path="softwareDevelopement" element={<SoftwareDevelopement />} />
 <Route path='ithardware'  element={<Ithardware/>}/>

  <Route path="ecommerceDesignAndDevelopement" element={<EcommerceDesignAndDevelopement/>} />
    <Route path="schoolmanagementsystem" element={<Schoolmanagementsystem />} />
    
    <Route path="travelandtourism" element={<Travelanstourism />} />
 
  <Route path="career" element={<Career />} />
  <Route path="cloud" element={<Cloud />} />
  <Route path="mobileappdev" element={<Mobileappdev />} />
  <Route path="webhosting1" element={<Webhosting1 />} />
  <Route path="product" element={<Product/>} />

  <Route path="*" element={<Page404/>} />
 </Routes>
 <Footer/>

  </>
  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom'
import "../../css/Promo.css"
import Carddata from "../pages/data/Data"
import { FaArrowRight } from "react-icons/fa";

const Promo = () => {
  return (
  <>
  <section className="promo-area pt-100 " style={{background:'#f8f9fa',marginBottom:'68px'}}>
    <div className="container">
        <div className="row justify-content-center mb-5" >
            <div className="col-lg-12 col-md-10 pb-70">
                <div className="section-heading text-center">
                    <h2 className='mb-3'>Proven Process, Real results in Software Developemnt </h2>
                    <p style={{color:'teal' ,fontSize:'16px',fontWeight:'600'}}>ANS Software is a one-stop source for your custom software/ Website / Mobile Apps development. We are one of the best  startups and fast-growing IT company with dedicated engineering teams, delivering scalable products that users love. Our Specialization is given below ... </p>
                </div>
            </div>
            
        </div>
        
        <div className="row d-flex">



        {
          Carddata.map((cards,id)=>{

return (





  <>
  
  <div className="col-lg-4 col-md-6" key={id} data-aos={cards.dataaos} data-aos-delay={cards.dataaosdelay}>
                    <div className="promo-box ">
                        <Link to={cards.url} style={{textDecoration:'none'}}>
                            <img src={cards.png} height={70} alt="Best software company in Odisha"/>
                            <h5>{cards.title}</h5>
                            <p className='text-center'>{cards.description}</p>
                          
                        </Link>
                    </div>
                </div>
  
  
  
  </>
)
 })
 }
                
        </div>
</div>
</section>
    
  
  
  
  
  
  
  </>
  )
}

export default Promo

import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../navbar/Navbar1.css";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import $ from "jquery";

import ans6 from "../images/ans6.png";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const Navbar1 = () => {
  useEffect(() => {
    $(window).on('scroll',function() {
      if ($(this).scrollTop() > 120){  
          $('.navbar').addClass("is-sticky");
      }
      else{
          $('.navbar').removeClass("is-sticky");
      }
    })
  });

 

  return (
    <>

<Navbar expand="lg" className="bg-white navbar">
      <Container>
        <Navbar.Brand href="#home" className="brand"><img src={ans6} alt="" style={{height:'30px'}}/><p  style={{fontSize:'25px',marginTop:"18px",marginLeft:'-.5px',color:'crimson',fontWeight:"bold"}} >Software</p>
       
</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"  className="navbar-toggle"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto navbar-nav">
            <Nav.Link as={Link} to="/" className="linkText">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className="linkText">About</Nav.Link>
            <NavDropdown  title={<span className="linkText">Services</span>} id="basic-nav-dropdown">
              
              <NavDropdown.Item as={Link} to="/webdesign" className="linkText1">Webdesign</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/softwareDevelopement" className="linkText1">Software Development</NavDropdown.Item>
              <NavDropdown.Item as={Link}to="/webhosting1" className="linkText1">Webhosting</NavDropdown.Item>
              <NavDropdown.Item as={Link}to="/ecommerceDesignAndDevelopement" className="linkText1">Ecommerce</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cloud" className="linkText1">Cloud</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/mobileappdev" className="linkText1">Mobile App Development</NavDropdown.Item>
               
              <NavDropdown.Item as={Link} to='/ithardware'  className="linkText1">IT Hardware Product</NavDropdown.Item>
                    </NavDropdown>
             <Nav.Link as={Link}to='/career' className="linkText">Career</Nav.Link>
             <Nav.Link as={Link}  to='/product' className="linkText">Product</Nav.Link>
             <Nav.Link as={Link} to="/contact" className="linkText">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
 </>
  );
};



export default Navbar1;

import React, { useCallback } from "react";
import "../../css/Banner.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import img from "../../images/hero-img.png";
import img1 from "../../images/shape-rotate .png";
import { FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
// import Featurecard from './Featurecard';

const Banner = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <>
     
        
      <div className="saas-banner py-5 ">
    <div className="container mt-5">
        <div className="row row align-items-center justify-content-center">

        <div className="col-lg-5 offset-lg-1 order-lg-1 d-flex align-items-center justify-content-center">
                <img src={img} className="img-fluid animated" alt="ANSsoftware" height={450}width={400}/>
            </div>
            
        <div className="col-lg-6 ">
        <div className="digital-agency-banner-content d-flex flex-column">
                                        <h1 className="text-light"><span>|| Website || Software </span>|| Mobile Apps || Digital Marketing Solution||</h1>
                                        <div className="banner-btn">
                                            <div className="d-flex">
                                                <Link to="tel:+91 9777431607" className="default-btn mt-3">
                                                    <i className="bx bxs-mobile"></i>
                                                    
                                               +91 9777431607, +91 9938369600
                                                </Link>
                                               
                                            </div>
                                        </div>
                                    </div>  
            
            
            
            </div>


           
           
        </div>
    </div>



        
        
        <div className="shape-rotate rotateme">
          <img src={img1} alt="img" />
        </div>

        <Particles
          id="particles-js"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#081e3b ",
              },
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: false,
                  mode: "push",
                },
                onHover: {
                  enable: false,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                directions: "clockwise",
                enable: true,

                random: true,
                speed: 4,
                straight: false,
                sync: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
      </div>
    </>
  );
};

export default Banner;

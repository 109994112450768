import design from '../../../images/design.png'
import software from '../../../images/software.png'
import itsolution from '../../../images/it-solution.png'


import mob  from '../../../images/mobile-responsive.png'
import ecommerce  from '../../../images/ecommerce1.png'
import cloud  from '../../../images/cloud.jpg'
import wifi  from '../../../images/wifi.png'
import ourvision  from '../../../images/our-vision.png'
import ourmission from '../../../images/ourmission.png'
import  whychhoseus from '../../../images/whychhoseus.jpg'
import  responsive2 from '../../../images/responsive2.png'
import  wordpress from '../../../images/wordpress.jpg'
import  landingpage2 from '../../../images/landing page2.png'
import  static2 from '../../../images/static2.jpg'
import  custom from '../../../images/custom.jpg'
import dynamic from '../../../images/dynamic.png'






 const Carddata = [
  {
   
 
    
    png: design,
    title: "Web Design and Development",
    description:'An expert web designing and web application development team can change your ideas into reality by using their creativity, and vast experience .Our websites design are unique in design and website designs itself represents the nature of your business on line .',
    dataaos:"fade-right",
    dataaosdelay:"200",
    url:'/webdesign'
  },
  {
    png:software,
    title: "Software Application Development",
    description:'Simplified Database, Latest Frameworks, Standard Development are the signs expertise to success used by our experienced software programmers.',
    dataaos:"fade-up",
    dataaosdelay:"200",
    url:'/softwareDevelopement'

  },
  {
    png: itsolution,
    title: "IT hardware Services",
    description:'We provide customized solutions for complete IT Solution including design, development, procurement and deployment from small to large business enterprises.',
    dataaos:"fade-left",
    dataaosdelay:"200",
    url:'/ithardware'

  
  },
    {
      png: mob,
      title: "Mobile Application",
      description:'We develope powerful.highly usable mobile apps that solve all your business problems,attract users and reinforce your brand',
      dataaos:"fade-right",
      dataaosdelay:"200",
      url:'/'
  
    },
    {
      png:ecommerce,
      title: "E-Commerce Website Development",
      description:'We develope powerful.highly usable mobile apps that solve all your business problems,attract users and reinforce your brand',
      dataaos:"fade-up",
      dataaosdelay:"200",
      url:'/ecommerceDesignAndDevelopement'
  
    },
    {
      png: cloud,
      title: "Cloud",
      description:'We develope powerful.highly usable mobile apps that solve all your business problems,attract users and reinforce your brand',
      dataaos:"fade-left",
      dataaosdelay:"200",
      url:'/cloud'
  
    }

      
    ];





 export const featuredata=[
  {
   
 
    
    png: wifi,
    dataaos:"zoom-in-down",
    dataaosdelay:"200",
   
    title: "ANS Software",
    description:"We clearly understand that Website is the face of your company online what you offer offline in your business. ANS Softwares always keeps our finger on the pulse on what's new. We have high degree of expertise in providing Interactive Web Solutions for our clients at affordable cost."
  },
  {
   
 
    
    png: ourvision ,
    dataaos:"zoom-in-down",
    dataaosdelay:"200",
   
    title: "Our Vision",
    description:"We will continuously delight our customers in current and new businesses by delivering superior value through enhanced offerings service on the internet and other platforms. We will do this by preserving our entrepreneurial spirit and leveraging."
  },
  {
   
 
    
    png: ourmission,
    dataaos:"zoom-in-down",
    dataaosdelay:"200",
   
    title: "Our Mission",
    description:"ANS Software helps you to transform your organization and improve performance.Our objective is to empower you to respond more quickly and intuitively to changing market dynamics.By bolstering your ability to harness the right technology,we help you become more agile and competitive."  },
  {
   
 
    
    png: whychhoseus ,
    dataaos:"zoom-in-down",
    dataaosdelay:"200",
   
    title: "Why Choose US ?",
    description:"We identify potential problems and correct them before they disrupt your business. Focus on your core competencies while having access to multiple tiers of IT expertise. Affordable Price without compromise with quality."
  },







];



export const aboutdata=[
{ url:'/webdesign',

    title: "Web Design and Development"
},
{ url:'/softwareDevelopement',
title: "Software Application Development",
},
{   url:'/ithardware',
title: "IT HardwareServices",
},
{  url:'/mobileappdev',
title: "Mobile Application"},
{url:'/ecommerceDesignAndDevelopement',
title: "E-Commerce Website Development"},
{url:"/cloud",

title: "Cloud",
}






  
]




export const webdesigndata=[

  {
   
 
    
    png:responsive2,
    title: "Responsive Web Design",
    description:'Make sure your website supports all kindsof electronic devices with varied sizes including mobile phones, tablets and PCs.We are able for designing mobile responsive web and our designing much better from others.The mobile responsive online interfaces developed by our exceptionally talented web developers give a smooth browsing experience to every one of the site users.',
    dataaos:"fade-right",
  
    
  },
{
  png:custom,
    title: "Custom Website Design",
    description:'Our superb Web Designers work with our Digital Strategy Consultants to comprehend the business procedure and objectives to apply with the right innovative components. Toward the end of the outcome, your site turns into an appealing and incredibly wonderful representation of your business on the web.',
    dataaos:"fade-up",
  
},
{png:static2,
  title: "Static Website Design",
  description:'Our ANS Software  comprises a group of expert, innovative substance journalists and SEO specialists in conveying significant and extraordinary substance with important catchphrases for your static pages. We have confidence in making our clients fulfilled by our best web architecture administrations.',
  dataaos:"fade-left",
},
{png:dynamic,
  title: "Dynamic Web Design",
  description:'ANS Software offers dynamic website composition, arrangements that make waves in the web world and return significant outcomes. Our group of expert fashioners has the artfulness to deliver a noteworthy web presence with the conveyance and organization of powerful sites and applications and has assisted endless organizations by having a significant online presence.',
  dataaos:"fade-right",
},
{png:wordpress,
  title: "Wordpress Website Design",
  description:'We develope word websites for corporates with specialism and industry specialized to have a better experience. For corporate website design and development, we include value addition and culture and user friendly for each web design. WordPress sites are very scalable. You can have hundreds of thousands of pages or blog posts on your site and the performance of the site will not be compromised in the least.',
  dataaos:"fade-up",
},
{png:landingpage2,
  title: "Landing Page Designing",
  description:'A landing page represents your brand. We create beautiful landing page which converts your visitors to leads.Our professional website design services in Odisha can help startup businesses to create a strong impression on the targeted audience. When we create a beautiful landing page for small business we keep it modern and informational so that new customers get clear understanding about your business objective.',
  dataaos:"fade-left",
}



]
export const softwaredev=[










  
]


export default Carddata








   
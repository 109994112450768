import React from 'react'
import Banner from './Banner'

import Main from './Main'
import Promo from './Promo'


const Home = () => {
  return (
    <div>
  <Banner/> 
    <Promo/>
    <Main/>
    
    
    </div>
  )
}

export default Home

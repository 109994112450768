import React from 'react'
import { Link } from 'react-router-dom'
import "../../css/Product.css"
import dataentry from "../../images/dataentry.jpg"
import dataentry1 from "../../images/dataentry2.jpg"

const Product = () => {
  return (
   <>

   <div className="blog-area ptb-10 bg-1 colorwhitebg">
   <hr style={{color:'red',fontWeight:'bold',height:'3px',marginBottom:'30px',marginTop:"90px"}}/>
  
   <div className="container">
   <h1 > School Management System</h1>
         <div className="row" >
        
    
           <div className="col-md-9 col-xs-12">
               <div className="row">
                   <div className="col-sm-12 col-xs-12 col">
                       <div className="blog-wrap ">
                           
                           <div className="blog-content">
                               <div className="about-wrap">
                  
                  
                   <p className="dtarsrch">SMS is a revolutionary management system introduced to simplify complex daily school operations. System effectively manages processes and operations related to teachers, students and courses.</p>

<p className="dtarsrch">SMS allows separate ID for each and every student of the school and allows them to access information directly as it maintains separate page of each student of the school. At one click of the button it provides personnel details ,mailing address last fee payment details, attendance details of each student.</p>

<p className="dtarsrch">Apart from student demographic information it provides information of detailed courses and period ,student grade books, progress report, report card, attendance information, exam management and constant and comprehensive evaluation of student performance</p>

                           
                           <p  className="dtarsrch"> 
SMS provides a user friendly interface which allows login access to teachers, nonteaching staff,students parents and management personnel of schools.</p>
<p  className="dtarsrch"> 
SMS can incorporate, can be customised for individual school requirements. Once installed; the managemennt, faculty ,students parents can use it for their individual requirements without any help from us.</p>
<p  className="dtarsrch"> 
At the same time guidence and any other support services required will be provided.
</p> 
<p className="dtarsrch">  
Control over the system through general settings like enabling grading system as per school requirements, automatic unique ID for all the users, Managing courses and classNamees, Managing subjects, School Setup, Homework Manager, Roll Number Master, Exam Hall Ticket Generation, Online Result & Marksheet with digital Signature & custom Headers, Manage Continuous and Comprehensive Exam Records (CCE), Home Page Manager, meta keywords manager for SEO, School Time Table, Exam Time Table, e-Learning Management, Study material management, News & Gallery Management, Course & Syllabus Management, Create & manage additional pages for website, manage navigation, Site menu/sub menu manager, Image Bank to use these images on any page of the site, Holiday/Vacation setting, Close/Create Academic session, monitor user/system log, hit counter monitor, Strong multiple search option, Export, Download, Print option on each page</p>
                     
                </div> 
                            
                           </div>
                       </div>
                   </div> 
                   
               </div>
                 </div>
       <div className="col-md-3 col-sm-6 col-xs-12 col ptb-30 ">
           
               <aside className="left-sidebar">
                   
                   
                     <div className="category-wrap mb-30">
                       <h3 className="sidebar-title">Core Modules</h3>
                       <ul className="dtarsrch">
<li><Link href="#" className="dtarsrch">Courses and Batches</Link></li> 
<li><Link href="#" className="dtarsrch">Student Attendance</Link></li> 
<li><Link href="#" className="dtarsrch">Time Table</Link></li> 
<li><Link href="#" className="dtarsrch">Examination</Link></li> 
<li><Link href="#" className="dtarsrch">Result Management</Link></li> 
<li><Link href="#" className="dtarsrch">Result Upload</Link></li> 
<li><Link href="#" className="dtarsrch">Result Entry</Link></li>  
<li><Link href="#" className="dtarsrch">User Management</Link></li> 
<li><Link href="#" className="dtarsrch">School/Event Calendar</Link></li> 
<li><Link href="#" className="dtarsrch">Student Information</Link></li> 
<li><Link href="#" className="dtarsrch">Employee/Teacher Login</Link></li> 
<li><Link href="#" className="dtarsrch">Student/Parent Login</Link></li> 
<li><Link href="#" className="dtarsrch">Front Website (CMS)</Link></li> 
<li><Link href="#" className="dtarsrch">Online Examination</Link></li> 
<li><Link href="#" className="dtarsrch">Homework</Link></li> 
 
<li><Link href="#" className="dtarsrch">Custom Report</Link></li> 

<li><Link href="#" className="dtarsrch">Setting & Configuration</Link></li> 
<li><Link href="#" className="dtarsrch">Backup/Restore Manager</Link></li> 

                       </ul> 
                   </div>
                   
                    
               </aside> 
<hr/> 
         
                
           </div>
       </div>






      
       <div className="row" >
       
       <h1  className="mt-5"> Travel & Tourism Management System</h1>
         <div className="col-md-9 col-xs-12">
             <div className="row">
                 <div className="col-sm-12 col-xs-12 col">
                     <div className="blog-wrap mb-30">
                         
                         <div className="blog-content">
                             <div className="about-wrap">
                
                
                 <p className="dtarsrch">The travel and tourism industry bounced back from the global economic slowdown of last year with 2011 poised to show a positive growth number. As a matter of fact, travel and tourism is one of the largest industries globally, both in terms of size and employment ($5.4 trillion and 8.6 per cent of the global workforce). In India, contrary to popular belief, it creates more jobs than the traditional industries of agriculture and automobiles and accounts for 5.9 per cent of GDP, making it the largest contributor after the oil and gas industry. </p>

<p className="dtarsrch">The future of tourism in India is certainly bright but we do have a long road ahead. Development of quality infrastructure will be the key to India's harnessing her full tourism potential. Internet has also added a new dimension to the business of travel with websites being used as a primary source of information and booking.</p>

<p className="dtarsrch"> The way forward for India tourism would be to have a streamlined approach so that we can widen the scope of the industry. With the explosion of technological advancements in the mid and late 20th century, tourists discovered increasing access to a growing array of destinations.</p>

                         
                         <p  className="dtarsrch"> 
                         From promoting destinations and attracting traveller attention to enable instant reservations and payment collection, communication technology continues to exhibit a significant impact on the tourism industry and travel in general.</p>

                   
              </div> 
                          
                         </div>
                     </div>
                 </div> 
                 
             </div>
               </div>
     <div className="col-md-3 col-sm-6 col-xs-12 col ptb-30 ">
         
             <aside className="left-sidebar">
                 
                 
                   <div className="category-wrap mb-30">
                     <h3 className="sidebar-title">Core Modules</h3>
                     <ul className="dtarsrch">
<li><Link href="#" className="dtarsrch">Customer & Contact Information Management</Link></li> 
<li><Link href="#" className="dtarsrch">Easy to use</Link></li> 
<li><Link href="#" className="dtarsrch">Integrate with accounting system</Link></li> 
<li><Link href="#" className="dtarsrch">Advanced Alert & Notification Management system</Link></li> 
<li><Link href="#" className="dtarsrch"> Navigation System & Rescue management system</Link></li> 


<li><Link href="#" className="dtarsrch">User Management</Link></li> 



                     </ul> 
                 </div>
                 
                  
             </aside> 
<hr/> 
       
              
         </div>
     </div>
       <div className="row mt-5">

       <div className="col-sm-6 col-xs-12 col">
       <div className="blog-wrap  mb-30">
       <div className="blog-img">
                           <img src={dataentry} alt="school-management" />
                       </div>
                       <div className="blog-content">
                       <br/>
                      <h3  className="sidebar-title"> Features </h3>
                      <div className="category-wrap">
                      <ul className="dtarsrch">
              <li>Beautiful Dashboard.</li>
              <li>Responsive Design.</li>
              <li>Affordable Pricing.</li>
              <li>School Website (CMS).</li>
              <li>Travel & Tourism(CMS).</li>
              <li>User friendly Management.</li>
              <li>Easy Customization.</li>
            </ul>
           </div>

                   </div>
       
       
       
       
       
       
       </div>
       
       
       
       
       
       
       </div>
       <div className="col-sm-6 col-xs-12 col">
       <div className="blog-wrap  mb-30">
            <div className="blog-img">
               <img src={dataentry1} alt="result-preparation" />
           </div>
           <div className="blog-content">
                                                         <br/>

               <h3  className="sidebar-title"> Why people choose us.</h3>
               <div className="category-wrap">
               <ul className="dtarsrch">
      <li>Complete solution for   School</li>
      <li>Complete solution for   Travel&Tourism</li>
       <li>Affordable Low Cost Solution</li>
       <li>User Friendly Structure</li>
       <li>Hosted on CLOUD</li>
       <li>Managed & Customized Services</li>
       <li>24 X 7 Online Support</li> 
     </ul>
    </div>
         
           </div>
       </div>
   </div>




       
       </div>    
            
                   
                   
   </div>
</div>
   
   
   </>
  )
}

export default Product

import React from 'react'
import "../../css/Mobileapp.css"
import mobileappdevelopmenticon from "../../images/mobile-app-development-icon.png"
import uiuxdesignicon from "../../images/ui-ux-design-icon.png"
import webappdevelopmenticon from "../../images/web-app-development-icon.png"
import prototypingsolutionsicon from "../../images/prototyping-solutions-icon.png"
import qatestingicon from "../../images/qa-testing-icon.png"
import iosappdevelopmenticon from "../../images/ios-app-development-icon.png"
import appdevelopment from "../../images/app-development.png"
import customersupport from "../../images/customer-support.png"


import mobileappdevelopmentcompan from "../../images/mobile-app-development-company.png"

const Mobileappdev = () => {
  return (
  <>
  
  <div className="imgbanner6 bg-img-3">
	<div className="container">
      <div className="row">
		  <div className="col text-center">
			  <h1>Mobile App Development Company In Odisha</h1>
		  </div>
      </div>
    </div>	
</div>

<section >
    <div className="container">
        <div className="row">
            <div className="col-lg-4 col--6 app_service">
                <img src={mobileappdevelopmenticon} alt="" className="w-25"/>
                <h6>Android App Development</h6>
                <p>We are a renowned android development company in Odisha offering powerful and intelligent solutions for mobile apps. Android development has always been near to our heart because of the development and licensing cost incurred.</p>
            </div>
            <div className="col-lg-4 col--6 app_service">
            <img src={uiuxdesignicon} alt="" className="w-25"/>
            <h6>UI/UX Design </h6>
            <p>Our skilled team works in the direction of building a user interface of high quality to entice users with the app. Our solutions for UX/UI are outstanding and intuitive.</p>
        </div>
          
            <div className="col-lg-4 col--6 app_service">
                <img src={webappdevelopmenticon} alt="" className="w-25"/>
                <h6>Web App Development</h6>
                <p>In a web mobile application development service, web develop highly progressive apps to encompass features like native apps. With a well-designed web app, users can access your application on multiple devices without much hassle.</p>
            </div>
          
            <div className="col-lg-4 col--6 app_service">
            <img src={prototypingsolutionsicon} alt="" className="w-25"/>
            <h6>Prototyping Solutions</h6>
            <p>Prototyping of mobile app is a crucial process and requires the involvement of both clients and our team of developers. We thereby help our clients to feel comfortable with our team and explain their needs in the best way. We allow our clients to test their app’s viability with this.</p>
        </div>
            <div className="col-lg-4 col--6 app_service">
                <img src={qatestingicon} alt="" className="w-25"/>
                <h6>QA & Testing </h6>
                <p>As the mobile app development is completed, it requires testing of the app for existing bugs. We use highly competent tools to perform quality analysis and test your app for parameters required to have in the app. We identify errors and correct them.</p>
            </div>
            <div className="col-lg-4 col--6 app_service">
            <img src={iosappdevelopmenticon} alt="" className="w-25"/>
            <h6>iOS App Development </h6>
            <p>We are glad to offer mobile app development in Odisha for the world’s most advanced operating system. Our primary approach remains to provide the epitome of user experience with augmented reality. We keep on researching AI and IoT to enhance the quality of app solutions.</p>
        </div>
         
        </div>
    </div>
</section>

<section>
    <div className="container service_heading">
        <div className="row justify-content-between align-items-center">
            <div className="clearfix">
                <img src={mobileappdevelopmentcompan} alt="" className="col-md-5 float-md-start img-fluid d-block m-auto"/>
                <h3>Cutting-Edge Mobile App Development</h3>
                <p>
                Do you have a business plan that requires mobile app solutions? Are you planning to hire a mobile app development company in Odisha? We are here to help you with mobile app development solutions for new or existing mobile apps. If you have been looking for a technology-driven, professional and bespoke mobile app development in Odisha, you are at the right place. With the experience of designing and building mobile app solutions for leading platforms like Android, iOS and Windows, we have evolved as fully functional mobile app development company with full-fledged services including Mobile App Development, Game Development, Quality Analysis & Testing, Migration, Upgrade, React Native App Development and UI/UX design.
                </p>
                <p>
                So, without much ado, reach to our expert developers for mobile app development solutions. We will definitely help you with some great mobile app solutions for your business.
                </p>
            </div>
        </div>
        <div className="row justify-content-between align-items-center my-5">
            <div className="clearfix">
                <img src={appdevelopment} alt="" className="col-md-5 float-md-end img-fluid d-block m-auto"/>
                <h3>Providing high mobile app development</h3>
                <p>
                We specialize in mobile app development in Odisha, Odisha and work out ways to encapsulate your vision and convert it into a workable mobile app solution. With a high-end and functional digital product, we aim to streamline the process of your business and make sure it touches the nerve of common and needy people. We try to make the app relevant as possible because happy users come back to the app, again and again, to explore it and find ways to accommodate it in their daily life.
                </p>
                <p>
                We also aim to revolutionize the app development process as an android app development company in Odisha to help our clients save money in the long run. With a smart, intuitive and highly balanced team, we adhere to impart impeccable work ethics and provide highly driven solutions. With our constant practice and high-end client satisfaction, we have enabled mobile applications with seamless user experience and superior features.
                </p>
                <p>
                You can also be a part of our long family and join the group of our ever-returning and happy clients. Place a call right now for quick assistance!!
                </p>
            </div>
        </div>
        <div className="row justify-content-between align-items-center">
        <div className="clearfix">
                <img src={customersupport} alt="" className="col-md-5 float-md-end img-fluid  d-block m-auto"/>
                <h3>customer support and satisfaction</h3>
                <p>
                With impeccable mobile application development experience, we can assure you of great features and accessibility. The best part about our app development services is that your time and resource matter to us as well. Therefore, we provide you with highly oriented solutions for mobile app development. We also have a highly skilled team that provides you with real-time updates and keeps you in sync with the latest updates. So, if you have been looking out for a mobile app development company in Odisha, you are at the right place. Fill the contact form now and send us our query.
                </p>
                <p>
                So far, we have worked for multiple industries.Our work experience is highly diverse.We keep polishing our skills with the evolving mobile app development industry and build your application from scratch.
                </p>
                <p>
                We discover your ideas and ponder about it ifs and buts for design creation. The development part always comes when you validate us for the prototype. Later, we always test your app and bring it on board to interact with users and get the business going.
                </p>
            </div>
        </div>
    </div>
  </section>

  
  
  
  
  
  </>
  )
}

export default Mobileappdev

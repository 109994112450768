import React from 'react'
import "../../css/Featurecard.css"
import {featuredata} from '../pages/data/Data'



const Featurecard = () => {
  return (
    <>
    
    <div className="features-card pt-100 pb-90  "style={{background:'#f8f9fa',marginBottom:'80px'}}>
    
    <div className='container mt-5  '>
    <div className=" row col-lg-12 col-md-12 col-sm-12">
    <div className="section-heading ">
        <h1  className='text-center mb-3 mt-5'>Best Software Development Team </h1>
        <p  className='text-center' style={{color:'teal',fontSize:'17px',fontWeight:'600',}}>ANS Software has a core team of Engineers, Designers and Strategists. We innovate the connection between brands. By using our technology management growing revenue is easier with software developed by us for your business</p>
    </div>
</div>
<div className="row d-flex">

{

featuredata.map((card,id)=>{

  
return(
<>
<div className="col-lg-3 col-sm-6 mt-5 " key={id} data-aos={card.dataaos} data-aos-delay={card.dataaosdelay}>
<div className="single-card text-center ">
    <img src={card.png} height={50} style={{marginBottom:"5px"}} />
    <h5>
      {card.title}
    </h5>

    <p>{card.description}</p>
</div>
</div>

</>
)
})


}





</div>
</div>



</div>
    
    
    
    </>
  )
}

export default Featurecard

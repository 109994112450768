import React from 'react'
import contactpng from "../../images/contact.png"
import "../../css/Contact.css"

const Contact = () => {
  return (
    <>
    <div class="page-area-contact">
    <img src={contactpng} alt="contact"  className="img-fluid"/>
    <div class="container">
        <div class="section-title text-center mt-5">
            <h4>Our <span style={{color:'crimson'}}>Contact</span></h4>
            <p></p>
        </div>
    </div>
</div>
<section class="container">

<div class="contact text-center">

    <h2 class="text-danger">The  Office Address :</h2>
    <address class=" text-black">
        1st Floor,<br />
        Backside of Popular Automobile, N.H-16,<br />
        Panikoili,opposite Sharma Petrolpump<br />
        Panikoili-755043, India.<br />
    </address>
    <address class="text-black text-center">
        
        Mobile:9777431607,9556634021,9938369600<br />
        Email :hr@anssoftware.com<br />
       
        
       
    </address>

</div>








</section>


    
    
    
    
    </>
  )
}

export default Contact

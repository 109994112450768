import React from 'react'
import img from '../../images/hosting.jpg'

import web from '../../images/web.jpeg'
import soft from '../../images/soft.jpg'
import marketing from '../../images/marketing.png'
import { Link } from 'react-router-dom'
import "../../css/Webhosting1.css"

const Webhosting1 = () => {
  return (
   <>
   <div className="slider bg-navy-blue bg-scroll pos-rel breadcrumbs-page">
   <div className="container">
     <nav aria-label="breadcrumb">
       <ol className="breadcrumb">
         <li className="breadcrumb-item"><a to="#"><i className="bx bxs-home" aria-hidden="true"></i></a></li>
         <li className="breadcrumb-item"><a to="#">Services</a></li>
         <li className="breadcrumb-item active" aria-current="page">Web Hosting</li>
       </ol>
     </nav>
     <h1>Web Hosting</h1>
      <div className="breadcrumbs-description">
   When a hosting provider allocates space web resources on a web server to operate a website.
   </div>
   </div>
 </div>
   
   <main id="body-content">
   <section className="wide-tb-80">
    <div className="container pos-rel">
      <div className="row align-items-start">
        <div className="col-md-12 col-lg-8">
        
           
              <div className="text-left"> <img src={img} alt="" className="rounded mb-4 img-fluid"/>
                <h4 className="h4-md mb-3 txt-blue fw-7">Web Hosting Services</h4>
                <p >Choose our perfect web hosting solution and make run your website faster. Shared hosting, VPS, Dedicated server, wordpress hosting, reseller hosting, etc. Best cheap web hosting services that fit your needs.</p>
                <p>Boost your business website with ANS Software web hosting services and get your online success! Enjoy our 99.9% Uptime and dedicated 24x7 support Hosting Services. Get Linux Hosting, Java Hosting, windows hosting and all types of hosting. Choose our India's Best hosting services provided by ANS Software Solutions. </p>
                <p>To Check our Hosting Plans <a to="#" target="_blank" ><b>Click Here</b></a></p>
              </div>
        
            
          
        </div>
        <div className="col-md-12 col-lg-4">
             <aside className="sidebar-spacer row">
              <div className="sidebar-primary col-lg-12 col-md-6">
                
               
              <div className="widget-wrap">
                <h3 className="h3-md fw-7 mb-4">Our Core Services</h3>
                <div className="blog-list-footer">
                  <ul className="list-unstyled">
                    <li>
                      <div className="media">
                        <div className="post-thumb"> <img src={web} alt="" className="rounded-circle"/> </div>
                        <div className="media-body post-text">
                          <h4 className=" "><Link to="#">Web Designing</Link></h4>
                          <p  className=" ">We are able for designing mobile responsive web and our designing much better from others.</p>
                        </div>
                      </div>
                    </li>
                    
                       <li>
                      <div className="media">
                        <div className="post-thumb"> <img src={soft} alt="" className="rounded-circle"/> </div>
                        <div className="media-body post-text">
                          <h4 ><Link to="#">Software Development</Link></h4>
                          <p>We are specialize for any type of software development.</p>
                        </div>
                      </div>
                    </li>
                    <li> 
                      <div className="media">
                        <div className="post-thumb"> <img src={marketing} alt="" className="rounded-circle"/> </div>
                        <div className="media-body post-text">
                          <h4 ><Link to="#">Digital Marketing</Link></h4>
                          <p>We are the one of the best company of deigital marketing in Odisha.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
  
            <div className="sidebar-secondary col-lg-12 col-md-6">
           
              <div className="widget-wrap">
              <h3 className=" fw-7 mb-4 txt-blue">Benefit of Hosting</h3>
              <ul className=" mb-0">
                <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s" style={{fontSize:"20px"}}></i>Broad platform support</li>
                <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s" style={{fontSize:"20px"}}></i>Datacenters worldwide</li>
                <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s" style={{fontSize:"20px"}}></i>Scalable from day one</li>
                <li><i className="bx bx-check" data-aos='fade-up' data-aos-duration="0" data-aos-delay="0s" style={{fontSize:"20px"}}></i>Flexible pricing models</li>
              </ul>
            </div>
              </div>
                 </div>
           
            </aside>
             
            </div>
      </div>
    </div>
  </section>

</main>
   
   </>
  )
}

export default Webhosting1

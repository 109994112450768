import React from 'react'
import "../../css/Main.css"

const Main = () => {
  return (
   <>
    
   <div  style={{marginBottom:'80px'}}>
   <div className="container pos-rel">
   <div className="row">
    
     
     <div className="col-sm-12" style={{marginTop:'-50px'}}>
       <h2 className=" text-center" > Our Goodness What Makes Us Special </h2>
     </div>
     
     <div className="col-12 col-lg-6" style={{marginTop:'50px'}}>
     <div>
       <div>
         <div> <i className="icofont-gears"></i> </div>
         <div>
           <h5 className="h4-md">Different Technology</h5>
           <p >We always use Latest and Secure technology for a Developing your website or for making a new software for you.</p>
         </div>
       </div>
     </div>
   </div>
   <div className="col-12 col-lg-6"  style={{marginTop:'50px'}}>
   <div>
     <div>
       <div className="service-icon"> <i className="icofont-users-social"></i> </div>
       <div>
         <h5 >Expert Team</h5>
         <p >We have a committed team Software and web Developer that achieve their goals in a given time period and an effective, timely manner.</p>
       </div>
     </div>
   </div>
   </div>
   <div className="col-12 col-lg-6"  style={{marginTop:'50px'}}>
   <div>
     <div className="media">
       <div > <i className="icofont-heart-alt"></i> </div>
       <div >
         <h5 >Excellent Customer Service</h5>
         <p >We take pride in our customer service. Our values and policies express the highest commitment to our customers.</p>
       </div>
     </div>
   </div>
   </div>
   
   <div className="col-12 col-lg-6"  style={{marginTop:'50px'}}>
   <div>
     <div >
       <div className="service-icon"> <i className="icofont-heart-alt"></i> </div>
       <div>
         <h5 >Years of Experience</h5>
         <p style={{textAlign:'justify'}}>We have 2+ year of experience for provide the Best Software Development, Web Designing,Web Hosting service. And we are to give our 100% to your work.</p>
       </div>
     </div>
   </div>
   </div>
   
   
   
   
   
   
   
   
   
   
       </div>
     </div>
       </div>
       
   
   
   
   </>
  )
}

export default Main
